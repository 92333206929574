<template>
	<pui-datatable
		:modelName="modelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:pageLength="20"
		:lengthMenu="[20]"
		:externalFilter="historicFilter"
		:modelColumnDefs="columnDefsHistoric"
		:masterDetailColumnVisibles="masterDetail ? historicColumns : masterDetailColumnVisibles"
		:showRowActions="false"
		:showActionsBtn="false"
		:showFilterListBtn="false"
		:readOnly="true"
		:navigableDetail="false"
		:masterDetail="masterDetail"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import { isSuperAdmin, formatTimestamp } from '@/api/common';

//import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';

export default {
	name: 'HistoricalNotificationsGrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'historicalnotifications',
			// No tabbed grid
			masterDetailColumnVisibles: {
				device: true,
				rulename: true,
				startdate: true,
				enddate: true,
				isaverage: true,
				ismobile: true
			},
			// Tabbed Grid
			historicColumns: {
				stationname: true,
				rulename: true,
				startdate: true,
				enddate: true,
				isaverage: true,
				ismobile: true
				//source: true
			},
			columnDefsHistoric: {
				rulename: {
					createdCell: (td, cellData, rowData) => {
						let ruleName = this.getRuleName(cellData);
						return (td.innerHTML = `<label class="fw-500" style="border-radius:4px;padding:2px 4px;background-color:${rowData.color + '99'};color:black">${ruleName}</label>`);
					}
				},
				startdate: {
					render: (data) => {
						return formatTimestamp(parseInt(data), this.userProperties);
					}
				},
				enddate: {
					render: (data) => {
						return formatTimestamp(parseInt(data), this.userProperties);
					}
				},
				isaverage: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				ismobile: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			isSuperAdmin: false,
			historicFilter: null
		};
	},
	computed: {
		deviceParameters() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmorganizationid == this.userProperties.organizationid);
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		this.setExternalFilter();
	},
	methods: {
		setExternalFilter() {
			let ruleNames = [];
			if (this.isSuperAdmin) {
				this.$store.getters.getRules && this.$store.getters.getRules.map((obj) => obj.acronym);
			} else {
				this.$store.getters.getRules &&
					this.$store.getters.getRules
						.filter((rule) => rule.pmorganizationid == this.userProperties.organizationid)
						.map((obj) => obj.acronym);
			}
			this.historicFilter = null;

			if (ruleNames.length > 0) {
				if (this.isSuperAdmin) {
					this.historicFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.historicFilter.groups.push({
							groupOp: 'and',
							rules: [{ field: 'rulename', op: 'eq', data: rule }]
						});
					});
				} else {
					this.historicFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.historicFilter.groups.push({
							groupOp: 'and',
							rules: [
								{ field: 'rulename', op: 'eq', data: rule },
								{ field: 'organizationid', op: 'eq', data: this.userProperties.organizationid }
							]
						});
					});
				}
			} else {
				if (!this.isSuperAdmin) {
					this.historicFilter = {
						groupOp: 'and',
						rules: [{ field: 'organizationid', op: 'eq', data: this.userProperties.organizationid }],
						groups: []
					};
				}
			}
			console.debug('dev hlp', ruleNames, this.historicFilter);
		},
		getRuleName(ruleName) {
			let rule = this.$store.getters.getRules && this.$store.getters.getRules.find((rule) => rule.acronym == ruleName);
			console.log('dev hlp rules', ruleName, rule);
			if (rule) return rule.name;
			return ruleName;
		}
	}
};
</script>

<style lang="postcss" scoped></style>
